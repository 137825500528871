exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-amenities-tsx": () => import("./../../../src/pages/amenities.tsx" /* webpackChunkName: "component---src-pages-amenities-tsx" */),
  "component---src-pages-booking-tsx": () => import("./../../../src/pages/booking.tsx" /* webpackChunkName: "component---src-pages-booking-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-island-tsx": () => import("./../../../src/pages/island.tsx" /* webpackChunkName: "component---src-pages-island-tsx" */),
  "component---src-pages-villa-tsx": () => import("./../../../src/pages/villa.tsx" /* webpackChunkName: "component---src-pages-villa-tsx" */)
}

